<template>
  <!-- <v-parallax :src="require('@/assets/employtec_bg.jpeg')"> -->
  <div id="employtec" class="py-12 white--text">
    <v-container class="content my-12 py-12">
      <h2
        class="display-2 mb-10 font-weight-bold"
        :class="{ 'text-center': $vuetify.breakpoint.mdAndUp }"
      >
        Brought to you by
      </h2>
      <v-row>
        <v-col
          v-for="(brand, index) in brands"
          :key="index"
          class="text-center"
          cols="12"
          md="2"
          :offset-md="index === 0 ? 3 : 0"
        >
          <a :href="brand.url" target="_blank" class="brand-logo">
            <v-img max-width="100" class="logo" :src="brand.logo" />
          </a>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <p class="text-center">
            The founding team run a number of other related businesses and
            community projects that directly or indirectly support WEARELOCALS
            and numerous clients in the public and private sector throughout the
            UK:
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <!-- </v-parallax> -->
</template>

<script>
export default {
  name: "Employtec",
  data() {
    return {
      brands: [
        {
          url: "https://employtec.com",
          logo: require("@/assets/employtec_logo.png"),
        },
        {
          url: "https://digitalconstruction.technology",
          logo: require("@/assets/dct_logo.png"),
        },
        {
          url: "https://www.towncentred.com",
          logo: require("@/assets/towncentred_logo.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#employtec {
  padding: $section-padding 0;
  background: linear-gradient(#0d47a1, #4f76af);

  .brand-logo {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    height: 160px;
    width: 160px;
    background: #ffffff;

    &:hover {
      .logo {
        transform: scale(1.05);
      }
    }

    .logo {
      transition: transform 0.2s ease-in-out;
      flex-grow: 0;
      margin: 0 auto;
      display: block;
    }
  }
}
.employtec-link {
  color: #004a94 !important;
  text-decoration: none;
  font-weight: bold;
}
</style>
